import React from 'react';
import { useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import Loading from '../../Shared/Loading';

const ViewBlogs = () => {

    const navigate = useNavigate()
    const { id } = useParams();

    const { data: blog, isLoading } = useQuery('blog', () => fetch(`https://parrots-academy-server-end.onrender.com/blog/${id}`).then(res => res.json()))


    if (isLoading) {
        return <Loading></Loading>
    }


    return (
        <div>
            <article>
                <header class="mx-auto max-w-screen-xl pt-8 text-center">
                    <p class="text-gray-500">{blog?.author}</p>
                    <h1 class="mt-2 text-3xl font-bold text-gray-900 sm:text-5xl">{blog?.headline}</h1>
                    <p class="mt-6 text-lg text-gray-700">{blog?.hook}</p>
                    <img class="sm:h-[50rem] mt-10 w-full object-contain" src={blog?.img} alt="Blog" />
                </header>
                <div class="mx-auto mt-10 max-w-screen-md space-y-12 px-4 py-10 font-serif text-lg tracking-wide text-gray-700">
                    {blog?.body}
                </div>
            </article>
        </div>
    );
};

export default ViewBlogs;
import React from 'react';

const Hero = () => {
    return (
        <div className="hero min-h-screen bg-hero-pattern">
            <div className="hero-overlay bg-opacity-30"></div>
            <div className="hero-content text-center text-neutral-content">
                <div className="max-w-xl">
                    <h1 className="mb-5 text-6xl font-bold"></h1>
                    <p className="mb-5 text-2xl"></p>
                </div>
            </div>
        </div>
    );
};

export default Hero;
import React, { useState } from 'react';
import hero2 from '../../Assets/Images/hero2.jpg';
import { Controller, useForm } from 'react-hook-form';
import Swal from 'sweetalert2';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const Appointment = () => {

    const { register, formState: { errors }, handleSubmit, control, reset, setValue } = useForm();
    const [date, setDate] = React.useState(new Date(Date.now()));
    const [selectedDate, setSelectedDate] = React.useState(new Date(Date.now()));

    const handleChange = (dateChange) => {
        setValue("selectedDate", dateChange, {
            shouldDirty: true
        });
        setSelectedDate(dateChange);
    };

    const formatDate = (date) => {
        const dates = `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
        return dates
    };

    const onSubmit = (data) => {
        const bookAopointment = {
            name: data.name,
            number: data.number,
            time: data.time,
            date: (formatDate(selectedDate)),
            email: data.email,
            purpose: data.purpose,
            description: data.description,
        };

        console.log(bookAopointment);
        fetch("https://parrots-academy-server-end.onrender.com/bookAppointment", {
            method: "POST",
            headers: {
                "content-type": "application/json",
            },
            body: JSON.stringify(bookAopointment),
        })
            .then((res) => res.json())
            .then((data) => {
                console.log(data);
                if (data.insertedId) {
                    console.log(data);
                    reset();
                    Swal.fire({
                        icon: "success",
                        title: "You booked an appointment. We will get back to you soon.",
                        showConfirmButton: false,
                        timer: 3500
                    });
                }
            });
    }


    return (
        <div>
            <div className="hero min-h-80">
                <div className="hero-content flex-col lg:flex-row-reverse">
                    <img src={hero2} className="max-w-sm rounded-lg shadow-2xl" alt='#' />
                    <div>
                        <h1 className="text-7xl font-bold">Book An<br /> Appointment <br /> With Us</h1>
                        <p className='py-6 pr-6 text-2xl'>Choose your preferred appointment time below, and we’ll confirm your booking via phone call soon!</p>
                    </div>
                </div>
            </div>
            <div className='flex justify-center'>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className='flex justify-evenly '>
                        <div>
                            <div>
                                <label for="name" className="block mb-2 text-sm text-gray-700">Full Name</label>
                                <input
                                    type="text"
                                    name="name"
                                    className="w-full px-3 py-2 placeholder-gray-500 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100 focus:border-indigo-300"
                                    {...register("name", {
                                        required: {
                                            value: true,
                                            message: 'Full name is required'
                                        }
                                    })}
                                />
                                <label className="label">
                                    {errors.name?.type === 'required' && <span className="label-text-alt text-red-500">{errors.name.message}</span>}
                                </label>
                            </div>

                            <div className='mt-1'>
                                <label for="number" className="block mb-2 text-sm text-gray-700">Phone Number</label>
                                <input
                                    type="text" name="number"
                                    className="w-full px-3 py-2 placeholder-gray-500 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100 focus:border-indigo-300"
                                    {...register("number", {
                                        required: {
                                            value: true,
                                            message: 'Valid contact number is Required'
                                        },
                                        pattern: {
                                            value: /(^(\+88|0088)?(01){1}[3456789]{1}(\d){8})$/,
                                            message: 'Provide a valid contact number'
                                        }
                                    })}
                                />
                                <label className="label">
                                    {errors.number?.type === 'required' && <span className="label-text-alt text-red-500">{errors.number.message}</span>}
                                    {errors.number?.type === 'pattern' && <span className="label-text-alt text-red-500">{errors.number.message}</span>}
                                </label>
                            </div>
                            <div className='mb-2'>
                                <label for="time" className="text-sm text-gray-700">Prefered time</label>
                                <select {...register("time")} name="time" className="select select-ghost w-full max-w-xs" refetch>
                                    <option disabled selected>Time Picker</option>
                                    <option>10am</option>
                                    <option>11am</option>
                                    <option>12pm</option>
                                    <option>1pm</option>
                                </select>
                            </div>
                        </div>
                        <div>
                            <div className='mt-1 mb-2 ml-5'>
                                <label for="email" className="text-sm text-gray-700">Email</label>
                                <input
                                    type="text" name="email"
                                    className="w-full px-3 py-2 placeholder-gray-500 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100 focus:border-indigo-300"
                                    {...register("email", {
                                        required: {
                                            value: true,
                                            message: 'Valid email is Required'
                                        },
                                        pattern: {
                                            value: /[a-z0-9]+@[a-z]+\.[a-z]{2,3}/,
                                            message: "Provide a valid Email",
                                        },
                                    })}
                                />
                                <label className="label">
                                    {errors.address?.type === 'required' && <span className="label-text-alt text-red-500">{errors.address.message}</span>}
                                    {errors.email?.type === "pattern" && (
                                        <span className="label-text-alt text-red-500">
                                            {errors.email.message}
                                        </span>
                                    )}
                                </label>
                            </div>
                            <div className='mt-1 mb-2 ml-5'>
                                <label for="purpose" className="text-sm text-gray-700">Purpose</label>
                                <select {...register("purpose")} name="purpose" className="select select-ghost w-full max-w-xs" refetch>
                                    <option disabled selected>Pick one</option>
                                    <option>IELTS Related</option>
                                    <option>Career Advice</option>
                                    <option>Study in Aboard</option>
                                </select>
                            </div>
                            <div className='mt-3 mb-2 ml-5'>
                                <label for="date" className="text-sm text-gray-700 mb-2">Prefered date</label>
                                <br />

                                <Controller
                                    name="selectedDate"
                                    control={control}
                                    defaultValue={date}
                                    render={() => (
                                        <DatePicker
                                            showIcon
                                            selected={selectedDate}
                                            onChange={handleChange}
                                            dateFormat="dd/MM/yyyy"
                                            filterDate={date => date.getDay() !== 5}
                                            minDate={new Date()}
                                            icon={
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="1em"
                                                    height="1em"
                                                    viewBox="0 0 48 48"
                                                >
                                                    <mask id="ipSApplication0">
                                                        <g fill="none" stroke="#fff" strokeLinejoin="round" strokeWidth="4">
                                                            <path strokeLinecap="round" d="M40.04 22v20h-32V22"></path>
                                                            <path
                                                                fill="#fff"
                                                                d="M5.842 13.777C4.312 17.737 7.263 22 11.51 22c3.314 0 6.019-2.686 6.019-6a6 6 0 0 0 6 6h1.018a6 6 0 0 0 6-6c0 3.314 2.706 6 6.02 6c4.248 0 7.201-4.265 5.67-8.228L39.234 6H8.845l-3.003 7.777Z"
                                                            ></path>
                                                        </g>
                                                    </mask>
                                                    <path
                                                        fill="currentColor"
                                                        d="M0 0h48v48H0z"
                                                        mask="url(#ipSApplication0)"
                                                    ></path>
                                                </svg>
                                            }
                                        />
                                    )}
                                />
                            </div>
                        </div>


                    </div>

                    <div>
                        <label for="description" className="block mb-2 text-sm text-gray-700">Description</label>
                        <textarea
                            type="text" name="description"
                            className="w-full px-3 py-2 placeholder-gray-500 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100 focus:border-indigo-300"
                            {...register("description", {
                                required: {
                                    value: true,
                                    message: 'Valid description is Required'
                                }
                            })}
                        />
                        <label className="label">
                            {errors.description?.type === 'required' && <span className="label-text-alt text-red-500">{errors.description.message}</span>}
                        </label>
                    </div>
                    <div className="mb-1 flex justify-end">
                        <input type="submit" className="max-w-sm px-3 py-2 text-white bg-indigo-500 rounded-md hover:bg-indigo-600 focus:outline-none duration-100 ease-in-out" value="Submit Request"></input>
                    </div>
                </form>
            </div >
        </div >
    );
};

export default Appointment;
import React from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Logo from '../../Assets/Images/Logos/parrotsLogo.png';
import { signOut } from 'firebase/auth';
import auth from '../../firebase.init';
import { useAuthState } from 'react-firebase-hooks/auth';
import useAdmin from '../../hooks/useAdmin';

const Navbar = () => {

    const user = useAuthState(auth);
    const [admin] = useAdmin(user);
    const navigate = useNavigate()
    const location = useLocation();
    let from = location.state?.from?.pathname || "/";

    const logout = () => {
        signOut(auth);
        navigate(from, { replace: true })
    }

    return (
        <header class="text-primary body-font">
            <div class="container mx-auto flex flex-wrap p-5 flex-col md:flex-row items-center font-semibold">
                <nav class="flex lg:w-2/5 flex-wrap items-center text-base md:ml-auto">
                    <Link to='/bookApppointment' class="mr-5 hover:text-gray-800">Appointment</Link>
                    <Link to='/studentPortal' class="mr-5 hover:text-gray-800">Student Portal</Link>
                    <Link to='/applyNow' class="mr-2 hover:text-gray-800">Apply Now</Link>
                </nav>
                <Link to='/' class="flex order-first lg:order-none lg:w-1/5 title-font font-medium items-center text-gray-900 lg:items-center lg:justify-center mb-4 md:mb-0">
                    <img src={Logo} width={60} height={60} alt="" />
                    <span class="ml-3 text-xl text-primary">Parrots Academy</span>
                </Link>
                <div class="lg:w-2/5 inline-flex lg:justify-end ml-5 lg:ml-0">
                    {(user[0]) ?
                        <>
                            <button onClick={logout} className="mt-1 menu menu-horizontal">Sign Out</button>
                        </>
                        :
                        <>
                            <Link to='/registerStudent' class="mr-2 mt-1 hover:text-gray-800">Register</Link>
                            <p className='mt-1 mr-2'>or</p>
                            <Link to='/loginStudent' class="mt-1 hover:text-gray-800">Log in as student

                            </Link>
                            {/* <span className='ml-1 mt-2'>
                                <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" class="w-4 h-4 ml-1" viewBox="0 0 24 24">
                                    <path d="M5 12h14M12 5l7 7-7 7"></path>
                                </svg>
                            </span> */}
                        </>}
                    {admin ? (
                        <>
                            <Link to='/AdminPanel' className="menu menu-horizontal">Admin Panel</Link>
                        </>
                    ) : (
                        ""
                    )}
                </div>
            </div>
        </header>
    );
};

export default Navbar;
import React from "react";
import { Link, Outlet } from "react-router-dom";
import { PiArchive, PiChalkboardTeacher, PiNotePencil, PiStudent, PiUserCircle, PiUserPlus, PiUsersFour } from "react-icons/pi";
import { PiBookOpenText } from "react-icons/pi";
import auth from "../../firebase.init";
import { useAuthState } from "react-firebase-hooks/auth";
import useAdmin from "../../hooks/useAdmin";
import Loading from "../Shared/Loading";

const AdminPanel = () => {
    const user = useAuthState(auth);
    const [admin] = useAdmin(user);

    if (!user[0]?.email) {
        return <Loading></Loading>;
    }

    return (
        <div>
            <div className="flex justify-start items-center">
                <label
                    tabIndex="1"
                    htmlFor="my-drawer-2"
                    className="btn btn-ghost lg:hidden"
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-5 w-5"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M4 6h16M4 12h8m-8 6h16"
                        />
                    </svg>
                </label>
            </div>
            <div className="drawer lg:drawer-open">
                <input id="my-drawer-2" type="checkbox" className="drawer-toggle" />
                <div className="drawer-content">
                    <Outlet></Outlet>
                </div>
                <div className="drawer-side">
                    <label htmlFor="my-drawer-2" className="drawer-overlay"></label>
                    <ul className="menu p-4 overflow-y-auto w-70 bg-base-100 text-base-content font-semibold text-md">
                        <li>
                            <Link className="focus:text-blue-500 mb-1" to="/AdminPanel">
                                <PiUserCircle className="text-xl" /> Appointments
                            </Link>
                        </li>
                        <li>
                            <Link className="focus:text-blue-500 mb-1" to="/AdminPanel/Applications">
                                <PiArchive className="text-xl" /> Applications
                            </Link>
                        </li>
                        <div className="divider"></div>
                        <li>
                            <Link
                                className="focus:text-blue-500 mb-1"
                                to="/AdminPanel/AddBlogs"
                            >
                                <PiBookOpenText className="text-xl" /> Add blogs
                            </Link>
                        </li>
                        <li>
                            <Link
                                className="focus:text-blue-500 mb-1"
                                to="/AdminPanel/ManageBlogs"
                            >
                                <PiNotePencil className="text-xl" /> Manage Blogs
                            </Link>
                        </li>
                        <div className="divider"></div>
                        <li>
                            <Link
                                className="focus:text-blue-500 mb-1"
                                to="/AdminPanel/AddStudents"
                            >
                                <PiUserPlus className="text-xl" /> Add Students
                            </Link>
                        </li>
                        <li>
                            <Link
                                className="focus:text-blue-500 mb-1"
                                to="/AdminPanel/ManageStudents"
                            >
                                <PiStudent className="text-xl" /> Manage Students
                            </Link>
                        </li>
                        <div className="divider"></div>
                        <li>
                            <Link
                                className="focus:text-blue-500 mb-1"
                                to="/AdminPanel/AddTeachers"
                            >
                                <PiChalkboardTeacher className="text-xl" />Add Teachers
                            </Link>
                        </li>
                        <li>
                            <Link
                                className="focus:text-blue-500 mb-1"
                                to="/AdminPanel/ManageTeachers"
                            >
                                <PiUsersFour className="text-xl" /> Manage Teachers
                            </Link>
                        </li>
                        <div className="divider"></div>
                        <li>
                            <Link
                                className="focus:text-blue-500 mb-1"
                                to="/AdminPanel/AllUsers"
                            >
                                <PiUserCircle className="text-xl" /> Add Users
                            </Link>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default AdminPanel;
